import React from "react"
import styled from "@emotion/styled"


import SEO from "../components/seo/seo"

import Layout from "../components/layout"

const ThanksContainer = styled.div`
  width: 100%;
`

const ThanksPage = () => {
  return (
    <Layout>
      <SEO
        title={`Thank You 👍`}
        description="Contact form"
        image="1.jpg"
        pathname={`/thanks`}
        website
      />
      <ThanksContainer>
        <h1>
          Thanks{" "}
          <span role="img" aria-label="victory hand">
            ✌️
          </span>
        </h1>
        <p>
          You're ace! I normally respond within a couple of days so stand by for
          the most awesome response you'll ever have recieved!
        </p>
      </ThanksContainer>
    </Layout>
  )
}

export default ThanksPage
